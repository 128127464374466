import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getOrders,
  Order,
  GetOrdersResponse,
  postOrderSuccess,
} from '../api/orderApi';
import { AxiosResponse } from 'axios';
import Grid from '../components/grid/Grid';
import Modal, { ModalSize } from '../components/modals/Modal';
import { AppState } from '../redux/reducers';
import {
  getActiveAccountId,
  getActiveAccountName,
} from '../selectors/envSelectors';
import Button from '../components/buttons/Button';
import ButtonGroup from '../components/buttons/ButtonGroup';
import Badge, { BadgeType } from '../components/badges/Badge';
import Alert, { AlertType } from '../components/alerts/Alert';
import PageHeader from '../components/layout/PageHeader';
import moneyUtils from '../utils/moneyUtils';
import dateUtils from '../utils/dateUtils';
import { OrderStatus, getOrderStatus } from '../utils/orderUtils';

interface AccountOrdersProps {
  accountId: string;
  accountName: string;
}

interface AccountOrdersState {
  limit: number;
  page: number | null;
  modalError: string | null;
  modalVisible: boolean;
  orders: Order[];
  selectedOrder: string | null;
  total: number;
  loading: boolean;
}

class AccountOrders extends Component<AccountOrdersProps, AccountOrdersState> {
  state = {
    limit: 25,
    page: 0,
    modalError: null,
    modalVisible: false,
    orders: [],
    selectedOrder: null,
    total: 0,
    loading: true,
  };

  componentDidMount = () => {
    if (this.props.accountId) {
      this.fetchOrders();
    }
  };

  componentDidUpdate = (prevProps: AccountOrdersProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchOrders());
    }
  };

  onChangePage = (params: any) => {
    this.setState({ page: params.page || 0 });
    return this.fetchOrders(params);
  };

  fetchOrders = (params: object = {}) =>
    getOrders(this.props.accountId, {
      limit: this.state.limit,
      page: this.state.page,
      ...params,
    }).then((res: AxiosResponse<GetOrdersResponse>) => {
      return this.setState({
        orders: res.data.items,
        total: res.data.total,
        loading: false,
      });
    });

  handleCheckout = () => {
    const orderId = this.state.selectedOrder;
    if (orderId) {
      postOrderSuccess(this.props.accountId, orderId)
        .then(() =>
          this.setState({ modalVisible: false }, () => {
            this.fetchOrders();
          })
        )
        .catch((err) => {
          let message =
            'We could not validate the checkout since ReCreateX returned an error. Try again to see if the checkout was successful.';
          if (err.response && err.response.status === 408) {
            message =
              'We could not validate the checkout due to a timeout. Try again to see if the checkout was successful.';
          }
          if (err.response && err.response.status === 409) {
            message = 'This order is already checked out in ReCreateX';
          }
          this.setState({
            modalError: message,
          });
        });
    }
  };

  render = () => (
    <div>
      <PageHeader>Orders</PageHeader>
      <Grid
        filters={[{ name: 'search' }]}
        isLoading={this.state.loading}
        actions={[
          { label: 'View', route: `/${this.props.accountName}/orders/:id` },
          {
            isVisible: (row: object) => {
              const order = row as Order;
              if ((order.payment || {}).status === 9 && !order.order_number) {
                return true;
              }
              return false;
            },
            label: 'Checkout',
            onClick: (row: object) => {
              const order = row as Order;
              this.setState({ modalVisible: true, selectedOrder: order.id });
            },
          },
        ]}
        columns={[
          {
            Header: 'Email',
            accessor: (row) => {
              const order = row as Order;
              return `${order.customer.email}`;
            },
          },
          {
            Header: 'Customer',
            accessor: (row) => {
              const order = row as Order;
              return `${order.customer.first_name} ${order.customer.last_name}`;
            },
          },
          {
            Header: 'Total amount',
            accessor: (row) => {
              const order = row as Order;
              return moneyUtils.toLabel(order.payment.amount);
            },
          },
          {
            Header: 'Order status',
            accessor: (row) => {
              const order = row as Order;
              const orderStatus = getOrderStatus(order);

              switch (orderStatus) {
                case OrderStatus.SUCCESS: {
                  return <Badge type={BadgeType.SUCCESS}>Successful</Badge>;
                }
                case OrderStatus.AWAITING_PAYMENT: {
                  return <Badge type={BadgeType.WARNING}>Pending</Badge>;
                }
                case OrderStatus.PAYMENT_FAILED: {
                  return <Badge type={BadgeType.ERROR}>Payment failed</Badge>;
                }
                default: {
                  return <Badge type={BadgeType.ERROR}>Failed</Badge>;
                }
              }
            },
          },
          {
            Header: 'Payment status',
            accessor: (row) => {
              const order = row as Order;
              const status = order.payment.status;
              if (order.payment.amount === 0) {
                return null;
              }
              if (status === 9) {
                return <Badge type={BadgeType.SUCCESS}>Paid</Badge>;
              }
              return <Badge type={BadgeType.ERROR}>Not Paid (yet)</Badge>;
            },
          },
          {
            Header: 'RecreateX ID',
            accessor: 'order_number',
          },
          {
            Header: 'Created At',
            accessor: (row) => {
              const order = row as Order;
              return dateUtils.toDateTimeLabel(order.created_at);
            },
          },
        ]}
        data={this.state.orders}
        limit={this.state.limit}
        onChange={(changeSet) => {
          return this.onChangePage({
            page: changeSet.page,
            search: changeSet.filter.search,
          });
        }}
        showPagination={true}
        total={this.state.total}
      />
      {this.state.modalVisible && (
        <Modal
          title="You are about to checkout this order in RecreateX"
          hide={() => this.setState({ modalVisible: false })}
          size={ModalSize.MD}
        >
          {this.state.modalError && (
            <Alert type={AlertType.ERROR}>{this.state.modalError}</Alert>
          )}
          <ButtonGroup>
            <Button onClick={() => this.handleCheckout()}>Checkout</Button>
            <Button onClick={() => this.setState({ modalVisible: false })}>
              Cancel
            </Button>
          </ButtonGroup>
        </Modal>
      )}
    </div>
  );
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state),
  accountName: getActiveAccountName(state),
});

export default connect(mapState)(AccountOrders);
